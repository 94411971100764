import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import Alert from 'react-bootstrap/Alert';
import Select from 'react-select';

import api from '../../../../utils/api';
import { getCurrencyName } from '../../currencies';
import { getParamCounterId } from '../../../../utils/utils';
import APIErrorHandler from '../../../../components/APIErrorHandler';

const { CancelToken } = axios;

function CreateUpdateDividendModal({ show, closeModal, dividendInit, handleSave }) {
  const source = CancelToken.source();
  const selectedPortfolio = useSelector((state) => state.portfolio.selected);
  const loading = useSelector((state) => state.portfolioDividend.loading);
  const response = useSelector((state) => state.portfolioDividend.response);
  const serverError = useSelector((state) => state.portfolioDividend.err);
  const [counterListOptions, setCounterListOptions] = useState(null);
  const [dividend, setDividend] = useState(dividendInit);
  const [exchangeCurrency, setExchangeCurrency] = useState(dividendInit.exchangeCurrency);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState(null);

  const additionalValidation = () => {
    const newError = {};

    if (dividend && !dividend.counter) {
      newError.stock = "Stock can't be blank";
    }

    setError(newError);
    return !(newError && Object.keys(newError).length > 0);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    const valid = additionalValidation();
    if (form.checkValidity() && valid) {
      handleSave(dividend);
    }

    setValidated(true);
  };

  const setExchangeRate = (newValue) => {
    if (dividend && dividend.exchangeRate === newValue) {
      return;
    }

    setDividend({
      ...dividend,
      ...{
        exchangeRate: newValue ? parseFloat(newValue) : null,
      },
    });
  };

  const getCurrencyCode = (counter) => {
    if (!counter) {
      return;
    }

    api
      .get(`/portfolio/${selectedPortfolio.id}/get_exchange_rate?counter=${counter}`, {
        cancelToken: source.token,
      })
      .then(({ data }) => {
        setExchangeRate(data.exchange_rate);
        setExchangeCurrency({
          value: data.currency_code,
          label: data.currency_name,
        });
      })
      .catch((err) => {
        setError({ ...error, ...{ exchangeRate: err.message } });
      });
  };

  const setStock = (newValue) => {
    if (dividend && newValue && dividend.counter === newValue.value) {
      return;
    }

    const newCounter = newValue ? newValue.value : null;
    setDividend({
      ...dividend,
      ...{
        counter: newCounter,
        stockName: newValue ? newValue.label : null,
      },
    });
  };

  const setShares = (newValue) => {
    if (dividend && dividend.shares === newValue) {
      return;
    }

    setDividend({
      ...dividend,
      ...{
        shares: newValue ? parseFloat(newValue) : null,
      },
    });
  };

  const setExDate = (newValue) => {
    if (dividend && dividend.exDate === newValue) {
      return;
    }

    setDividend({
      ...dividend,
      ...{
        exDate: newValue,
      },
    });
  };

  const setPayDate = (newValue) => {
    if (dividend && dividend.payDate === newValue) {
      return;
    }

    setDividend({
      ...dividend,
      ...{
        payDate: newValue,
      },
    });
  };

  const setAmount = (newValue) => {
    if (dividend && dividend.amount === newValue) {
      return;
    }

    setDividend({
      ...dividend,
      ...{
        amount: newValue ? parseFloat(newValue) : null,
      },
    });
  };

  const setNotes = (newValue) => {
    if (dividend && dividend.notes === newValue) {
      return;
    }

    setDividend({
      ...dividend,
      ...{
        notes: newValue,
      },
    });
  };

  const customSelectStyles = {
    option: (provided, state) => ({
      whiteSpace: 'unset',
      lineBreak: 'auto',
      wordBreak: 'break-word',
    }),
  };

  const handleClose = () => {
    closeModal();
    setValidated(false);
    setError(null);
  };

  const formatOptionLabel = (data) => {
    return (
      <div>
        <div className="text-wrap">{data.label}</div>
        <div className="text-muted g-text-size-12 text-wrap">({getParamCounterId(data.value)})</div>
      </div>
    );
  };

  const calculateTotal = () => {
    const amount = dividend.amount ? parseFloat(dividend.amount) : 0;
    const shares = dividend.shares ? parseFloat(dividend.shares) : 0;
    const exchangeRate = dividend.exchangeRate ? parseFloat(dividend.exchangeRate) : 0;
    return (amount * shares * exchangeRate).toLocaleString('en-SG');
  };

  useEffect(() => {
    if (
      !selectedPortfolio.counters ||
      !(selectedPortfolio.counters && selectedPortfolio.counters.length)
    ) {
      return;
    }
    const options = selectedPortfolio.counters.map(({ Name, counter }) => ({
      value: counter,
      label: Name,
    }));
    setCounterListOptions(options);
  }, [selectedPortfolio.counters]);

  useEffect(() => {
    if (validated) {
      additionalValidation();
    }
  }, [dividend?.counter]);

  useEffect(() => {
    getCurrencyCode(dividend.counter);
  }, [dividend.counter]);

  useEffect(() => {
    if (response) {
      handleClose();
    }
  }, [response]);

  useEffect(() => {
    if (show) {
      setDividend(dividendInit);
      setExchangeCurrency(dividendInit.exchangeCurrency);
    }
  }, [show]);

  return (
    <Modal show={show} centered dialogClassName="g-text-size-14" onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{dividend && dividend.id ? 'Edit' : 'Add'} Dividend</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {serverError && <APIErrorHandler error={serverError} message_={serverError.message} />}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12">
              <Form.Group className="g-mb-20">
                <Form.Label>
                  <b>Stock</b>
                </Form.Label>
                <div className={error?.stock ? 'form-error' : ''}>
                  <Select
                    isClearable
                    backspaceRemovesValue
                    name="stock"
                    placeholder="Search stock"
                    className="dividendFormSelect react-select-container"
                    classNamePrefix="react-select"
                    value={
                      dividend &&
                      dividend.counter && {
                        value: dividend.counter,
                        label: dividend.stockName,
                      }
                    }
                    onChange={(val) => setStock(val)}
                    options={counterListOptions}
                    formatOptionLabel={(data) => formatOptionLabel(data)}
                    styles={customSelectStyles}
                  />
                </div>
                <Form.Control.Feedback type="invalid" className={error?.stock ? 'd-block' : ''}>
                  {error?.stock}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <Form.Group className="g-mb-20">
                <Form.Label>
                  <b>Dividend per share</b>
                </Form.Label>

                <Form.Control
                  type="number"
                  name="dividendPerShare"
                  value={dividend.amount}
                  onChange={(e) => setAmount(e.target.value)}
                  step="0.00001"
                  min="0.00001"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Amount must be a positive number.
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col-md-6 col-sm-12">
              <Form.Group className="g-mb-20">
                <Form.Label>
                  <b>Shares</b>
                </Form.Label>

                <Form.Control
                  type="number"
                  name="shares"
                  placeholder="Number of shares"
                  value={dividend.shares}
                  onChange={(e) => setShares(e.target.value)}
                  step="0.0001"
                  min="0.0001"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Shares must be a positive number.
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          {exchangeCurrency && (
            <div className="row">
              <div
                className="col-12"
                hidden={exchangeCurrency.value === selectedPortfolio.currency}>
                <Form.Group className="g-mb-20">
                  <Form.Label>
                    <b>Exchange Rate</b>
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      className="z-0"
                      type="number"
                      name="exchangeRate"
                      value={dividend.exchangeRate}
                      onChange={(e) => setExchangeRate(e.target.value)}
                      step="0.0001"
                      min="0.0001"
                      required
                    />
                    <InputGroup.Text className="g-text-size-14">
                      {exchangeCurrency.label} to {getCurrencyName(selectedPortfolio.currency)}
                    </InputGroup.Text>
                    <Form.Control.Feedback type="invalid">
                      Exchange Rate must be a positive number.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <Form.Group className="g-mb-20">
                <Form.Label>
                  <b>Ex-date</b>
                </Form.Label>
                <Form.Control
                  type="date"
                  name="exDate"
                  value={dividend.exDate}
                  onChange={(e) => setExDate(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Ex-date must be a valid date
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col-md-6 col-sm-12">
              <Form.Group className="g-mb-20">
                <Form.Label>
                  <b>Payable date</b>
                </Form.Label>
                <Form.Control
                  type="date"
                  name="payableDate"
                  value={dividend.payDate}
                  onChange={(e) => setPayDate(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Payable date must be a valid date
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Form.Group className="g-mb-20">
                <Form.Label>
                  <b>Note:</b>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  name="notes"
                  value={dividend.notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </Form.Group>
            </div>
          </div>

          <div className="row g-mb-20 g-mt-20 g-pt-20 g-pb-20 footer-lower">
            <div className="col-4 text-center align-items-center">
              <strong>Total ({getCurrencyName(selectedPortfolio.currency)})</strong>
            </div>
            <div className="col-8 text-left align-items-center">
              <strong>{calculateTotal()}</strong>
            </div>
          </div>

          <div className="g-mb-20 g-mt-20 text-center">
            <Button variant="primary" type="submit" className="text-center" disabled={loading}>
              {dividend && dividend.id ? 'Update' : 'Create'} Dividend
              <span hidden={!loading}>
                <i className="fa-solid fa-spinner-third g-ml-10" />
              </span>
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

CreateUpdateDividendModal.propTypes = {
  show: PropTypes.bool,
  closeModal: PropTypes.func,
  dividendInit: PropTypes.object,
  handleSave: PropTypes.func,
};

export default CreateUpdateDividendModal;
