import React from 'react';

import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import SettingsInputField from './SettingsInputField';

const SHOW_INDICE = {
  0: 'Hide',
  1: 'Show',
};

function SettingsDisplay({ displayShowIndice }) {
  // Default to 0 if not passed
  const indiceShowConfig = {
    key: 'display_show_indice',
    name: 'Hide Indices Chart on the Header',
    icon: 'fa-light fa-chart-column',
    inputType: 'radio',
    value: displayShowIndice,
    options: SHOW_INDICE,
  };

  return (
    <Card className="g-mb-30">
      <Card.Body>
        <Card.Title className="h5">Display Settings</Card.Title>
        <ul className="list-unstyled">
          <li className="g-mb-10">
            <SettingsInputField fieldConfig={indiceShowConfig} />
          </li>
        </ul>
      </Card.Body>
    </Card>
  );
}

SettingsDisplay.propTypes = {
  displayShowIndice: PropTypes.bool,
};
export default SettingsDisplay;
