import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  setDefaultUserSettingsState,
  updateUserSettings,
  resetFormState,
} from './userSettingsSlice';
import APIErrorHandler from '../../../components/APIErrorHandler';
import SettingsConnectedApps from './SettingsConnectedApps';
import SettingsPrimaryMarket from './SettingsPrimaryMarket';
import SettingsPrices from './SettingsPrices';
import SettingsFinancials from './SettingsFinancials';
import SettingsDisplay from './SettingsDisplay';

const { CancelToken } = axios;
function UserSettingsMain({ settings, thirdPartyAuthApps }) {
  const dispatch = useDispatch();
  const connectedApps = useSelector((state) => state.userSettings.connected_apps);
  const formState = useSelector((state) => state.userSettings.form_state);
  const message = useSelector((state) => state.userSettings.message);
  const completed = useSelector((state) => state.userSettings.completed);
  const error = useSelector((state) => state.userSettings.err);
  const source = CancelToken.source();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateUserSettings({ settings: formState, cancelToken: source.token }));
  };

  const handleReset = (e) => {
    e.preventDefault();
    // reset to last saved form data
    dispatch(resetFormState());
  };

  useEffect(() => {
    dispatch(setDefaultUserSettingsState({ settings, thirdPartyAuthApps }));
  }, [settings, thirdPartyAuthApps]);

  useEffect(() => {
    if (completed) {
      toast.success(message || 'Update Successfully', {
        position: 'top-center',
        transition: Bounce,
      });
    } else if (error) {
      toast.error(message || 'Update Failed', {
        position: 'top-center',
        transition: Bounce,
      });
    }
  }, [completed, error, message]);

  if (error) {
    return <APIErrorHandler error={error} />;
  }

  return (
    <Form onSubmit={handleSubmit}>
      <ToastContainer position="top-center" transition={Bounce} />
      <SettingsConnectedApps connectedApps={connectedApps} />
      <SettingsPrimaryMarket market={formState.primary_market} />
      <SettingsPrices
        timeAndSalesPerPage={formState.time_and_sales_records_per_page}
        quoteMovementsPerPage={formState.quote_movements_records_per_page}
        historicalPricePerPage={formState.historical_price_records_per_page}
      />
      <SettingsFinancials
        financialsShowChart={formState.financials_show_chart}
        financialsDisplayOrder={formState.financials_display_order}
        financialsFilter={formState.financials_filter}
        financialsPeriod={formState.financials_period}
      />
      <SettingsDisplay displayShowIndice={formState.display_show_indice} />
      <div className="d-flex justify-content-center gap-3 g-mt-20 g-mb-40">
        <Button variant="primary" type="submit">
          Update settings
        </Button>
        <Button variant="dark" onClick={handleReset}>
          Reset
        </Button>
      </div>
    </Form>
  );
}

UserSettingsMain.propTypes = {
  settings: PropTypes.oneOfType([PropTypes.object]),
  thirdPartyAuthApps: PropTypes.oneOfType([PropTypes.array]),
};

export default UserSettingsMain;
