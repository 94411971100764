import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';

import { fetchData } from './stockPricesSlice';
import PrintButton from '../../components/PrintButton';

const { CancelToken } = axios;

function ActionButtons() {
  const filters = useSelector((state) => state.stockPrices.filters);
  const data = useSelector((state) => state.stockPrices.data);
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const [csvData, setcsvData] = useState([]);  

  const handleRefresh = () => {
    dispatch(fetchData({ filters: filters, cancelToken: source.token }));
  };

  const handleCopy = () => {
    if (!data) { return; }

    let header = [];
    let data_fields = [];
    header = Object.keys(data.stock_info[0]);
    header.unshift('No');
    header = header.filter(x => x !== 'counter');
    data_fields.push(header.join('\t'));

    data.stock_info.map((c, index) => {
      let row = [];
      header.map((f) => {
        if (f == 'No') {
          row.push(index+1);
        }
        else {
          row.push(c[f]);
        }
      });
      data_fields.push(row.join('\t'));
    });
    const copy_string = data_fields.join('\n');

    try {
      navigator.clipboard.writeText(copy_string);
    } catch (error) {
      console.error('Error copying text:', error);
    }
  };

  useEffect(() => {
    if (!data) { return; }
    const copyDisplayCounters = data.stock_info.map((c, index) => {
      if (c.hasOwnProperty('counter')) {
        const { counter, ...rest } = c;
        c = rest;
      }

      return {
        No : index + 1,
        ...c
      }

    });  
    setcsvData(copyDisplayCounters);
  }, [data]);

  return (
    filters &&
    filters.layout &&
    filters.layout !== 'trading_data' && (
      <>
        <button type="button" className="btn btn-light" onClick={handleRefresh}>
          <i className="fa-thin fa-arrows-rotate fa-lg g-mr-5" /> Refresh
        </button>
        <PrintButton
          title=""
          autoPrint={false}
          disabled={!(data && data.stock_info)}
          printNodeID="dt-sic_pricesTable"
        />
        <CSVLink
          data={csvData}
          filename={`Prices-${filters.market}-${filters.layout}-ShareInvestor`}
          target="_blank"
          className="btn btn-light">
          <i className="fa-thin fa-file-export fa-lg g-mr-5" /> Export to Excel
        </CSVLink>
        <button type="button" className="btn btn-light" onClick={handleCopy}>
          <i className="fa-thin fa-copy fa-lg g-mr-5" /> Copy to Clipboard
        </button>
      </>
    )
  );
}

ActionButtons.propTypes = {};

export default ActionButtons;
